import * as Ariakit from "@ariakit/react";
import { cx } from "~/design/generated/css";
import { type PopoverVariantProps, popover } from "~/design/generated/recipes";

type PopoverProps = Ariakit.PopoverProps &
  PopoverVariantProps & {
    className?: string;
  };

type PopoverDisclosureProps = Ariakit.PopoverDisclosureProps;

export function PopoverDisclosure(props: PopoverDisclosureProps) {
  return <Ariakit.PopoverDisclosure {...props} />;
}

export function Popover(props: PopoverProps) {
  const { children, store, className, ...restProps } = props;
  const [popoverProps] = popover.splitVariantProps(props);

  return (
    <Ariakit.Popover
      gutter={4}
      portal={true}
      modal={true}
      store={store}
      className={cx(popover(popoverProps), className)}
      {...restProps}
    >
      {children}
    </Ariakit.Popover>
  );
}
