import { Fragment } from "react/jsx-runtime";
import * as Ariakit from "@ariakit/react";
import { Popover, PopoverDisclosure } from "./Popover";
import { Button } from "./Button";
import { useRootData } from "~/hooks/useRootData";
import { css } from "~/design/generated/css";
import { ButtonLink } from "./ButtonLink";
import { Text } from "./Text";
import { flex } from "~/design/generated/patterns";

function Logged({ store }: { store: Ariakit.PopoverStore }) {
  const { user } = useRootData();
  return (
    <div className={flex({ direction: "column", gap: 6 })}>
      <div className={flex({ direction: "column", gap: 2 })}>
        <Text variant="label-1">{user?.username}</Text>
        <Text variant="label-2">{user?.email}</Text>
      </div>

      <ButtonLink size="sm" to="/account" full variant="outline" onClick={store.hide}>
        Account
      </ButtonLink>
    </div>
  );
}

function Unlogged({ store }: { store: Ariakit.PopoverStore }) {
  return (
    <div className={flex({ direction: "column", gap: 2 })}>
      <ButtonLink size="sm" to="/account/settings" full variant="outline" onClick={store.hide}>
        Settings
      </ButtonLink>
      <ButtonLink size="sm" to="/login" full variant="outline">
        Login
      </ButtonLink>
    </div>
  );
}

export function HeaderPopover() {
  const { user } = useRootData();

  const store = Ariakit.usePopoverStore({
    placement: "bottom-end",
  });

  return (
    <Fragment>
      <PopoverDisclosure
        store={store}
        render={(props) => {
          const { color, ...rest } = props;
          return (
            <Button {...rest} variant="ghost">
              Account
            </Button>
          );
        }}
      />

      <Popover store={store} className={css({ p: 3, width: "64" })}>
        {user ? <Logged store={store} /> : <Unlogged store={store} />}
      </Popover>
    </Fragment>
  );
}
