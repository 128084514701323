import type { ReactNode } from "react";
import { Container } from "./Container";
import { Header } from "./Header";

export type AppLayoutProps = {
  children?: ReactNode;
};

export function AppLayout({ children }: AppLayoutProps) {
  return (
    <Container variant={"full"}>
      <Header />
      {children}
    </Container>
  );
}
