import { css } from "~/design/generated/css";
import { Logo } from "./Logo";
import { Link } from "@remix-run/react";
import { ButtonLink } from "./ButtonLink";
import { MeiliSearchSearchbar } from "./MeiliesearchSearchbar";
import { flex } from "~/design/generated/patterns";
import { HeaderPopover } from "./HeaderPopover";

export function Header() {
  return (
    <div
      className={flex({
        // position: "sticky",
        // top: 0,
        // bg: "elevation.page",
        // zIndex: 10000,
        mx: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        height: "calc(var(--horizontal-bar-height) + var(--gutter)  + var(--gap) )",
        pb: "var(--gap)",
        pt: "var(--gutter)",
        gap: 2,
      })}
    >
      <Link to="/">
        <Logo />
      </Link>
      <div className={css({ width: "600px" })}>
        <MeiliSearchSearchbar />
      </div>
      <div className={flex({ display: { base: "none", md: "flex" } })}>
        <ButtonLink to="/blog" variant="ghost">
          Blog
        </ButtonLink>
        <HeaderPopover />
      </div>
    </div>
  );
}
